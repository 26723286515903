import React, {useState, useEffect, useMemo} from'react';
import{makeStyles, withStyles} from'@material-ui/core/styles';
import Typography from'@material-ui/core/Typography';
import Button from'@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField'
import moment from "moment"
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list'

countryList().setEmpty('').getLabel('')

const SelectWithOther = ({otherHelper="", otherLabel='Other' , label='', value, options=[], onChange=Function.prototype, helperText='', noOthers=false}) => {
  const optionWithOthers = noOthers ? options: [...options, otherLabel]
  const [other, setOther]= useState(false)

  const handleChage = (value) => {
    if(value !== otherLabel) {
      onChange(value)
      setOther(false)
    } else {
      onChange("")
      setOther(true)
    }
  }
  return (
  <>
    <TextField
      fullWidth
      label={label}
      margin="dense"
      onChange={(event) => {handleChage(event.target.value)}}
      select
      value={other ? otherLabel : value}
      SelectProps={{ native: true }}
      variant="outlined"
      helperText={helperText}
    >
      <option aria-label="None" value={undefined} />
      {optionWithOthers.map((v) => (<option  key={v} value={v}>{v}</option>))}
    </TextField>
    {other && !optionWithOthers.map(v => v.value).includes(value) &&             
      <TextField
        fullWidth
        margin="dense"
        value={value}
        helperText={otherHelper}
        /*error={validate.single(record[source], schema.title) ? true : false}*/
        onChange={(event) => onChange(event.target.value)}
        variant="outlined"
      />
    }
  </>)
}


const TITLES =  [
  "Manager",
  "Engineer",
  "Teacher",
  "Analyst",
  "Sales Representative",
  "Consultant",
  "Accountant",
  "Developer",
  "Administrator",
  "Researcher",
  "Designer",
  "Doctor",
  "Technician",
  "Executive",
  "Entrepreneur",
  "Artist",
  "Financial Advisor",
]


const currentOccuList = [
  "Employed full-time",
  "Employed part-time",
  "Self-employed",
  "Entrepreneur",
  "Student",
  "Unemployed",
]

const levelEduList = [
  "No formal education",
  "Elementary school",
  "Middle school",
  "High school diploma",
  "Professional certification or license",
  "Some college, no degree",
  "Associate's degree",
  "Bachelor's degree",
  "Master's degree",
  "Doctoral degree",
  "Post-doctoral degree",
]

const studyFieldList = [
  "Arts and Humanities",
  "Business and Management",
  "Computer Science and Information Technology",
  "Education and Training",
  "Engineering and Architecture",
  "Law and Public Policy",
  "Medical and Health Sciences",
  "Natural Sciences",
  "Social Sciences",
]


const YearsOfExpList = [
  "Less than 1 year",
  "1-2 years",
  "3-5 years",
  "6-10 years",
  "More than 10 years",
]


const useStyles = makeStyles((theme) => ({
  div : {
    display : 'flex',
  },
  noCheckbox : {
    color: theme.palette.error.main,
    '&$checked': {
      color: theme.palette.error.main,
    }
  },
  button : {
    width : '100%',
    display : 'flex',
    marginTop: '20px',
    //justifyContent : 'center'
  },
  truebutton : {
    width: '145px',
    height: '48px',
    background: '#026FC2 0% 0% no-repeat padding-box',
    boxShadow: '0px 10px 20px #00000033',
    borderRadius: '5px',
  }
}));

export default function Start({title : _title, start = Function.prototype}) {
  const classes = useStyles();

  const [title, setMainTitle]   = useState('');
  const [gender, setGender]     = useState('');
  const [birthday, setBirthday] = useState('');
  const [yearsExp, setYearsExp] = useState('');
  const [studyField, setStudyField]       = useState('');
  const [levelEdu, setLevelEdu] = useState('');
  const [currentOccu, setCurrentOccu] = useState('');
  const [startupLunch, setStartupLunch] = useState('');

  

  const [checked, setChecked] = useState(false);

  const [open, setOpen] = React.useState(false);

  const { t, i18n, ready } = useTranslation();
  const [country_residence, changeCountryOfResidence] = useState('')
  const [country_birth, changeCountryOfBirth] = useState('')

  const countries_options = useMemo(() => 
    countryList().getData().map(v => ({
      value: v.value,
      label: t(v.label)
    })), [t])


  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const h2 = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div style={{
        maxWidth: "576px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center"
      }}>     
        <Typography  variant={h2 ? "h2" : "h1"} color='primary' paragraph>{t(_title)}</Typography>

      <div className={classes.div}>
   
          <div style={{width : "100%"}}>
            <TextField
              fullWidth
              className={classes.button} 
              label={t('Gender')}
              margin="dense"
              onChange={(event) => {setGender(event.target.value)}}
              select
              value={gender}
              SelectProps={{ native: true }}
              variant="outlined" 
            >
            <option aria-label="None" value={null}>{t('Prefer not to say')}</option>
              {[
                {label: 'Male', value: 'Male'}, 
                {label: 'Female', value: 'Female'},
              ].map((v) => (<option  key={v.label} value={v.value}>{t(v.label)}</option>))}
            </TextField>

            
            <TextField
              label={t('Birthday')}
              margin="dense"
              type="date"
              variant="outlined" 
              inputProps={{ max: moment(Date.now()).format('YYYY-MM-DD')}}
              value={birthday}
              className={classes.button}
              onChange={(event) => {setBirthday(event.target.value)}}
              InputLabelProps={{
                shrink: true,
              }}
            />  


            <TextField
              fullWidth
              label={t('Country of birth')}
              margin="dense"
              onChange={(event) => {changeCountryOfBirth(event.target.value)}}
              select
              value={country_birth}
              SelectProps={{ native: true }}
              variant="outlined" 
            >
              {countries_options.map((v) => (<option  key={v.label} value={v.value}>{v.label}</option>))}
            </TextField>

            <TextField
              fullWidth
              label={t('Country of residence')}
              margin="dense"
              onChange={(event) => {changeCountryOfResidence(event.target.value)}}
              select
              value={country_residence}
              SelectProps={{ native: true }}
              variant="outlined" 
            >
              {countries_options.map((v) => (<option  key={v.label} value={v.value}>{v.label}</option>))}
            </TextField> 

            <SelectWithOther 
              options={currentOccuList.map(v => t(v))} 
              value={currentOccu} 
              onChange={setCurrentOccu} 
              label={t('Current occupation')}
              //helperText={t('Current occupation')}
              otherLabel={t("Other (please specify)")}
            />

            <SelectWithOther 
              options={levelEduList.map(v => t(v))} 
              value={levelEdu} 
              onChange={setLevelEdu} 
              label={t('Level of education')}
              helperText={t('What is the highest level of education you have completed?')}
              otherLabel={t("Other (please specify)")}
            />

          <SelectWithOther 
              options={studyFieldList.map(v => t(v))} 
              value={studyField} 
              onChange={setStudyField} 
              label={t('Field of study')}
              otherLabel={t("Other (please specify)")}
              //helperText={t('What is the highest level of education you have completed?')}
            />

            <SelectWithOther 
              options={YearsOfExpList.map(v => t(v))} 
              value={yearsExp} 
              onChange={setYearsExp} 
              label={t('Years of professional experience')}
              other=''
              noOthers
              otherLabel={t("Other (please specify)")}
              //helperText={t('What is the highest level of education you have completed?')}
            />

            <SelectWithOther 
              options={TITLES.map(v => t(v))} 
              value={title} 
              onChange={setMainTitle} 
              label={t('Main title')}
              helperText={t('Background or Future occupation if student')}
              otherLabel={t("Other (please specify)")}
            />

            <SelectWithOther 
              options={["No"].map(v => t(v))} 
              value={startupLunch} 
              onChange={setStartupLunch} 
              label={t('Have you ever launched a startup?')}
              //helperText={t('If yes, for how many years have you been running your startup?')}
              otherLabel={t("Yes")}
              otherHelper={t("For how many years have you been running your startup?")}
            />

            <div style={{display : 'flex',     justifyContent: 'space-between'}}>
                <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  name="checked"
                  color="primary"
                />
              }
              label={
                <Typography style={{display: "flex"}}>{t("I agree with ")}<Link component="button"  style={{marginLeft : "5px"}} onClick={() => setOpen(true)}>{t("terms and conditions")}</Link></Typography>}
            />
            <Dialog onClose={handleClose} fullWidth  open={open} scroll="body" >
              <iframe src="/CGU.pdf#toolbar=0" scrolling="no"  style={{width:"100%" , height:"500px", overflow: "hidden"}}></iframe>
            </Dialog>
            </>
  
            {(
              checked && 
              title &&
              birthday &&
              yearsExp &&
              studyField &&
              levelEdu &&
              currentOccu &&
              startupLunch
              ) && <Button className={classes.truebutton} align='center'  
            onClick={start.bind(null, {title, birthday : moment(birthday).format('MM-DD-YYYY'), gender, metadata: {
              yearsExp,
              studyField,
              levelEdu,
              currentOccu,
              gender,
              birthday : moment(birthday).format('MM-DD-YYYY'),
              title,
              startupLunchYears: startupLunch && startupLunch !== "No" ? startupLunch : ""
            }})} variant="contained" color="primary" >{t("START")}</Button>}
          </div>
          </div>
      
      </div>

    </div>
  );
}




import React, { useEffect, useRef } from'react';


import Lotti from 'lottie-web'


//import Animation9 from '../../animations/data9.json';8


/*
import Animation0 from '../../animations/data6.json';
import Animation1 from '../../animations/data7.json';
import Animation2 from '../../animations/data10.json';
import Animation3 from '../../animations/data1.json';
import Animation4 from '../../animations/data5.json';
import Animation5 from '../../animations/data11.json';
import Animation6 from '../../animations/data3.json';
import Animation7 from '../../animations/data8.json'; // to change
import Animation8 from '../../animations/data4.json';
import Animation9 from '../../animations/data2.json';
*/

import Animation10 from '../../animations/ANIM/data1.json'; //7
import Animation11 from '../../animations/ANIM/data2.json'; //5
import Animation12 from '../../animations/ANIM/data3.json'; //9
import Animation13 from '../../animations/ANIM/data4.json'; //6
import Animation14 from '../../animations/ANIM/data5.json'; //8
import Animation15 from '../../animations/ANIM/data6.json';  //4
import Animation16 from '../../animations/ANIM/ScreenMaster_1080.json'; //2
import Animation17 from '../../animations/ANIM/Home anim_1080.json'; // to change
import Animation18 from '../../animations/ANIM/home1.json';  //4
import Animation19 from '../../animations/ANIM/home2.json';  //4




import Animation0 from '../../animations/data6.json';
import Animation1 from '../../animations/ANIM/data3.json';
import Animation2 from '../../animations/ANIM/Home anim_1080.json';
import Animation3 from '../../animations/ANIM/home3.json';  //4

import Animation4 from '../../animations/ANIM/data6.json';
import Animation5 from '../../animations/ANIM/data2.json';
import Animation6 from '../../animations/ANIM/data4.json';
import Animation7 from '../../animations/ANIM/data1.json'; // to change
import Animation8 from '../../animations/ANIM/data5.json';
import Animation9 from '../../animations/ANIM/data3.json';


const animations = [
  Animation0, Animation1, Animation2, Animation3, Animation4, Animation5, Animation6, Animation7, Animation8, Animation9,
  Animation10, Animation11, Animation12, Animation13, Animation14, Animation15, Animation16, Animation17, Animation18, Animation19
];


export default function Lottie({animationIndex = -1}) {
  const inputEl = useRef(null);
  useEffect(() => {
    var anim = Lotti.loadAnimation({
      container: inputEl.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: animations[animationIndex]
    })
    anim.addEventListener("enterFrame", function (animation) {
      if (animation.currentTime >= (animation.totalTime - 1))
         anim.pause();
   });

    
    return function cleanup() {anim.destroy()}
  }, [animationIndex]);

  return (
    <div ref={inputEl} style={{zIndex : -1, pointerEvents : 'none', height: '100%', overflow : "hidden"}}></div>
  )
} 

import React, { useRef, useEffect, Suspense  } from 'react';
import View from './views/Routes';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Switch, Redirect, BrowserRouter as Router} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ThemeProvider } from '@material-ui/styles';
import './assets/scss/index.css';

import theme from './theme';
import './i18n';

import "@fontsource/tajawal/400.css" // Defaults to weight 400.
import "@fontsource/tajawal/500.css" // Defaults to weight 400.




const browserHistory = createBrowserHistory();


const headers = {
  'x-hasura-admin-secret' : 'idjem',
}

const client = new ApolloClient({
  uri: window._env_.APP_HASURA_URL,
  cache: new InMemoryCache(),
  headers
});





function App() {
  return (
    <ApolloProvider client={client}>
          <ThemeProvider theme={theme('rtl')}>

            <CssBaseline />
            <Router history={browserHistory} basename={process.env.PUBLIC_URL}>
              <View />
            </Router>

          </ThemeProvider>

    </ApolloProvider>
  );
}

export default App;
import React, {useState} from 'react';

import { useTranslation } from 'react-i18next';

import {
  useHistory,
} from "react-router-dom";

import{makeStyles, withStyles} from'@material-ui/core/styles';

import { useQuery, gql, useMutation } from '@apollo/client';
import { Box, CircularProgress, Grid, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'


const useStyles = makeStyles((theme) => ({
  div : {
    display : 'flex',
    margin: 'auto'
  },
  noCheckbox : {
    color: theme.palette.error.main,
    '&$checked': {
      color: theme.palette.error.main,
    }
  },
  button : {
    width : '100%',
    display : 'flex',
    marginTop: '8px',
  },
  truebutton : {
    width: '100%',
    marginTop: '8px',
  }
}));


const QUERY = gql`
  query session($sessionUrl: uuid!) {
    session: sessions(where: {sessionUrl : {_eq : $sessionUrl}}) {
      id
    }
  }
`;

const InsertResulQuery =  gql`
mutation  insert_participant($firstName : String,
  $lastName : String,
  $email : String, 
  $sessionId: Int){
  insert_participant(email : $email, firstName : $firstName, lastName : $lastName, sessionId : $ sessionId){
    id
  }
}
`;


export default function Index(props) {
  const {sessionUrl} = props;

  const classes = useStyles();

  const { loading, error, data } = useQuery(QUERY, {variables : {sessionUrl }});
  const [update, { loading : loadingMutation }] = useMutation(InsertResulQuery);

  const { t, i18n, ready } = useTranslation();
  let history = useHistory();
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setemail] = useState('');
  const [errors, setError] = useState('');


  if (loading || !data || !ready)  return <Box display='flex' height='100%'><CircularProgress style={{margin : 'auto'}}/></Box>

  

  //const start = () => history.push(`/${resultId}/start`); 
  if(error || errors) return <Box display='flex' height='100%'>Something went wrong</Box>


  if (loading || !data || !ready)  return <Box display='flex' height='100%'><CircularProgress style={{margin : 'auto'}}/></Box>

  const session = data.session[0]

  console.log(session)

  return (
    <Grid container style={{height: "100%"}}>
        <div className={classes.div}>
        <div style={{width : "100%"}}>
            <TextField
                fullWidth
                className={classes.button} 
                label={t('First Name')}
                margin="dense"
                onChange={(event) => {setfirstName(event.target.value)}}
                value={firstName}
                variant="outlined" 
            />
            <TextField
                fullWidth
                className={classes.button} 
                label={t('Last Name')}
                margin="dense"
                onChange={(event) => {setlastName(event.target.value)}}
                value={lastName}
                variant="outlined" 
            />
            <TextField
                fullWidth
                className={classes.button} 
                label={t('Email')}
                margin="dense"
                onChange={(event) => {setemail(event.target.value)}}
                value={email}
                variant="outlined" 
            />
         <Button className={classes.truebutton} disabled={(!email || !firstName || !lastName || loadingMutation)} align='center'  
            onClick={async () => {
                try {
                    var data = await update({variables: {firstName, lastName, email, sessionId: session.id}})
                    history.push(`/${data.data.insert_participant.id}/start`)
                } catch(err) {
                    console.log(err)
                    setError(err)
                }

            }} variant="contained" color="primary" >{t("Submit")}</Button>
        </div>
     </div>
  </Grid>
  )}



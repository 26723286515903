import React from 'react';



import { useQuery, gql, useMutation } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import Intro from './Intro'
import More from './MoreAboutYou'

import Question from './Question'
import FileUpload from './FileUpload'
import { Grid } from '@material-ui/core';
import { NoFragmentCyclesRule } from 'graphql';
import Lottie from './Lottie';
import { Hidden } from '@material-ui/core';
import { useState } from 'react';


const QUERY = gql`
query NextAssignment($id: uuid!) {
  assignment : nextAssignment(id: $id) {
    next
  }
  results_by_pk(id: $id) {
    session {
      id
      feedback
    }
  }
}
`;

const MUTATION = gql`
mutation update($id : uuid!, $response : jsonb){
  update_response(response : $response, id :  $id){
    id
  }
}
`;


export default function Index(props) {
  const {resultId} = props;



  const { loading, error, data, refetch } = useQuery(QUERY, {variables : {id : resultId}});
  const [update, { loading : loadingMutation }] = useMutation(MUTATION, {onCompleted : () => refetch()});


  if (loading &&  loadingMutation)  return <Box display='flex' height='100%'><CircularProgress style={{margin : 'auto'}}/></Box>



  if(!data)
    return null;
    

  if(!data.assignment.next)
    return null;
  const question = data.assignment.next.question;
  const feedback = data.results_by_pk.session.feedback;
  const chapter  =  data.assignment.next.chapter;
  
  const {animation} = data.assignment.next;

  var main = <div />;

  //const resultTxt = `Bravo ! You just completed the test.\n\nIt took you ${secondToTimeString(Math.floor(d.duration / 1000))} to complete, not bad :)\n\nThanks for your honesty, time and efforts.\n\nYou will receive a brief summary to your email account. Feel free to share it around!`

  const resultTxt = `Thanks for your honesty, time and efforts.\n\nYou will receive a brief summary to your email account. Feel free to share it around!`


  if(data.assignment.next.end)
    main = <Intro title={"Bravo !"} subtitle={resultTxt} noButton/>

  else if(question.type === 'statement')
    main = <Intro title={question.title} elements={question.data.elements} subtitle={question.data.description} start={() => update({ variables: { id : data.assignment.next.id, response : {done : 'ok'} } })}/>
  else if(question.type === 'fileUpload')
    main = <FileUpload title={question.title} subtitle={question.data.description} feedback={feedback} start={(response) => update({ variables: { id : data.assignment.next.id, response } })}/>
  else if(question.type === 'question')
    main = <Question feedback={["PERSONALITY TRAITS", "DISC"].includes(chapter) && feedback} title={question.title} id={question.id} {...question.data} onClick={(response) => update({ variables: { id : data.assignment.next.id, response }})}/>
  else if(question.type === 'multipleQuestion')
    main = <More title={question.title} start={(response) => update({ variables: { id : data.assignment.next.id, response } })}/> 
  return  <Grid container style={{height: "100%"}}>
      <Grid xs={12}  md={animation ? 6 : 12}  style={{height: "100%"}}>
        {main}
      </Grid>
      <>
      {animation ? 
            <Hidden smDown>
       <Grid xs={5}>
        <Lottie animationIndex={animation}/>
        </Grid>
  </Hidden>: null}
     {animation ?  <Hidden smDown>
       <Grid xs={1}>
     </Grid></Hidden> : null}
     </>
      </Grid>
    
  

}

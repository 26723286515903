import React from 'react';

import{makeStyles, withStyles} from'@material-ui/core/styles';
import { AppBar, Toolbar, LinearProgress, Typography, Button } from '@material-ui/core';
import defer from 'nyks/promise/defer';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button : {
    width: '145px',
    height: '48px',
    background: '#026FC2 0% 0% no-repeat padding-box',
    boxShadow: '0px 10px 20px #00000033',
    borderRadius: '5px',
    marginRight: '42px'
  },
  button2 : {
    width: '145px',
    height: '48px',
    border: "1px solid #026FC2",
    borderRadius: "5px",
    opacity: 1
  }
}));


const AppBarLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: '#FBCD00',
  },
  root: {
    height: 2,
  },
  bar: {
    backgroundColor: '#3CBA8B',
  },
}))(LinearProgress);


export default function Start({title = '', subtitle = '', start, feedback=false}) {

  const { t, i18n, ready } = useTranslation();


  const handleCapture = async ({target}) => {
    var reader = new FileReader();
    var defered = defer();

    reader.addEventListener('load', () => {
      defered.resolve(reader.result);
    }, false);

    reader.readAsArrayBuffer(target.files[0]);
    try {
      const data = await defered;
      const res  = await fetch(`${window._env_.APP_BACKEND_URL}/upload/linkedin`, {method : 'PUT', body : data});
      const json = await res.json();

      console.log(json)
      start({linkedIn : json.key})

    } catch(err) {
      console.log(err)
      start({linkedIn : false, detail : 'error'})
    }

  };


  const classes = useStyles();

  const theme = useTheme();
  const h2 = useMediaQuery(theme.breakpoints.down('md'));

  
  return (
    <div style={{
      maxWidth: "576px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center"
    }}>     
      <Typography  variant={h2 ? "h2" : "h1"} color='primary' paragraph>{t(title)}</Typography>
      <div>
        {
          subtitle.split((/(\r\n|\n|\r)/gm)).map((v, i) => <Typography  paragraph variant="body2"  key={`${v}${i}`}>{t(v.trim())}</Typography>)
        }
      </div>
      <div style={{display : 'flex',     marginTop: '20px'}}>
      <Button className={classes.button} variant="contained" color="primary" component="label">
        {t('UPLOAD')}
        <input
          type="file"
          accept="*"
          onChange={handleCapture}
          style={{display : 'none'}}
        />
      </Button>
      {!feedback && <Button className={classes.button2}  color="primary" onClick={() => start({linkedIn : false, detail : 'skip'})}>{t('SKIP')}</Button>}
      </div>

    </div>
  );
}






import React,  { useState, useEffect }  from'react';
import Toolbar from './ToolBar';
import{makeStyles } from'@material-ui/core/styles';

import { gql, useQuery } from '@apollo/client';


import Lottie from './Lottie'
import { CircularProgress, Hidden } from '@material-ui/core';


import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    paddingLeft: 189,
    paddingTop:"128px",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 18,
      paddingRight: 18
    }
  },

  content: {
    height: '100%',
    minHeight: "500px"
  }


}));




const QUERY = gql`
  query result($id : uuid!) {
    assignment : nextAssignment(id: $id) {
      next
    }
    
    survey_info(id: $id) {
      chapters
      duration
      totalQuestion
    }

  
  }
`





export default function Layout({children = null, resultId}) {
  const classes = useStyles();

  var {data} = useQuery(QUERY, {fetchPolicy : 'cache-first', variables : {id : resultId}});


  if(!data)
    return null;


  const {chapter, order, end} = data.assignment.next;

  const {chapters, totalQuestion} = data.survey_info;



  return (
    <div style={{height : '100%'}}>
    <div className={classes.root}>
    <Toolbar chapters={[...chapters, 'END']} chapter={end ? 'END' : chapter} progression={end ? 100 : (order / totalQuestion) * 100} hideTimer={end}/>
      <main className={classes.content}>
        {children}
      </main>
     </div>
     </div>
    );
}

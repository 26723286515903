import React from 'react';

import{makeStyles, withStyles} from'@material-ui/core/styles';
import { AppBar, Toolbar, LinearProgress, Typography, Button } from '@material-ui/core';
import Components from '../Components';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button : {
    width: '145px',
    height: '48px',
    background: '#026FC2 0% 0% no-repeat padding-box',
    boxShadow: '0px 10px 20px #00000033',
    borderRadius: '5px',
    marginTop: '20px'
  }
}));


const AppBarLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: '#FBCD00',
  },
  root: {
    height: 2,
  },
  bar: {
    backgroundColor: '#3CBA8B',
  },
}))(LinearProgress);


export default function Start({elements = [], title = '', subtitle = '', start, noButton = false, buttonTitle='next'}) {

  const classes = useStyles();

  const theme = useTheme();
  const h2 = useMediaQuery(theme.breakpoints.down('md'));
  const { t, i18n, ready } = useTranslation();

  if(elements.length)
    return     <div style={{
      maxWidth: "576px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center"
    }}>
      {elements.map(block => Components(block))}
      <Button className={classes.button} variant="contained" color="primary" onClick={start}>{t('NEXT')}</Button>

    </div>
  return (
    <div style={{
      maxWidth: "576px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center"
    }}>     
      <Typography  variant={h2 ? "h2" : "h1"} color='primary' paragraph>{t(title)}</Typography>
      <div>
        {
          subtitle.split((/(\r\n|\n|\r)/gm)).map((v, i) => <Typography  paragraph variant="body2"  key={`${v}${i}`}>{t(v.trim())}</Typography>)
        }
      </div>
      {noButton ? null : <Button className={classes.button} variant="contained" color="primary" onClick={start}>{t(buttonTitle)}</Button>}
    </div>
  );
}




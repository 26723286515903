import React from'react';
import{makeStyles, withStyles} from'@material-ui/core/styles';
import { AppBar, Toolbar, LinearProgress, Typography, Badge, Hidden } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useTranslation } from 'react-i18next';

const AppBarLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: '#FBCD00',
  },
  root: {
    height: 2,
  },
  bar: {
    backgroundColor: '#3CBA8B',
  },
}))(LinearProgress);



const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor:"#ffffff"
  },
  logo : {
    paddingLeft: 147,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 18,
    }
  },
  chapter : {
    marginTop : "14px", 
    marginRight : '52px', 
    fontWeight : 400,
  },
  nav : {
    width : '100%', 
    display: 'flex', 
    justifyContent : 'flex-end'
  },
  timer : {
    transform: progression => `translateX(${theme.direction === 'rtl' ? -progression : progression}%)`, 
    marginRight : "146px"
  }, 
  timerText : {
    color : '#274093', fontWeight : 'bold', marginLeft : '4px'
  }
}));




const totalTime = 25;


export default function ToolBar({chapters, chapter, progression = 0, hideTimer}) {
  
  const classes = useStyles(progression);

  const selectedChapter = chapters.indexOf(chapter);
  const { t, i18n, ready } = useTranslation();


  const chapterColor = (index) => {
    if(selectedChapter === index) return '#1F3A93';
    else if(selectedChapter < index) return '#9DAFBD';
    return '#3CBA8B'
  }

  return (
    <>
    <AppBar    
      className={classes.root}
      >
      <Toolbar elevation={0}  style={{minHeight : '84px'}}>
        <img
          className={classes.logo}
          alt="Logo"
          src="/images/logos/logo_sml.png"
        />
        <Hidden mdDown>
        <div className={classes.nav}>
          {chapters.map((label, index) => 
            <>
            <DoneIcon style={{ color: chapterColor(index)}} />
            <Typography className={classes.chapter} style={{ color : chapterColor(index)}} key={label} variant="h5" >
              {t(label)}
            </Typography>
            </>
          )}
        </div>
        </Hidden>

      </Toolbar>
      
      <AppBarLinearProgress variant="determinate" value={progression} />
      {hideTimer ? null : <div className={classes.timer}  >
        <div style={{display : 'flex', alignItems : "center", marginTop : '12px', color : '#274093'}}>
        <AccessTimeIcon fontSize='small'/>
        <Typography variant="caption" className={classes.timerText} >       
          {totalTime - Math.floor((totalTime * progression) / 100)} {t('minutes')}
        </Typography>
        </div>
      </div>}
      </AppBar>
     </>
    );
}

import React,  { useState, useEffect }  from'react';
import{makeStyles } from'@material-ui/core/styles';

import { gql, useQuery } from '@apollo/client';


import { CircularProgress } from '@material-ui/core';


import { useTranslation } from 'react-i18next';

import { StylesProvider, jssPreset } from "@material-ui/styles";
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import theme from '../../theme';


import { create } from "jss";
import rtl from "jss-rtl";


const QUERY = gql`
  query result($id : uuid!) {

    survey_info(id: $id) {
      resources
    }

    result : results_by_pk(id : $id) {
      session {
        id
        language {
          language
          id
          LCID
        }
      }
    }
  
  }
`


var load = (src) => new Promise((resolve) => {
  var image = new Image();
  image.onload = resolve;
  image.src = src;
});


function Resources({children, resources = []}) {
  const [loaded, setLoaded] = useState(false);

useEffect(() => {
  const loadRecources = async () => {
    await Promise.all(resources.map((src) => load(src)));
    setLoaded(true);
  };
  loadRecources();
}, [resources.length]);

return  loaded ? children : <div style={{display : "flex", height : "100%"}}><CircularProgress style={{margin : "auto"}} disableShrink /></div>
}



export default function Index({children = null, resultId}) {

  var {data} = useQuery(QUERY, {fetchPolicy : 'cache-first', variables : {id : resultId}});

  const {i18n} = useTranslation();

  if(!data)
    return null;

  const {LCID, language}  = data.result.session.language;

  if(i18n.language !== LCID) {
    i18n.changeLanguage(LCID);
  }


  const {resources} = data.survey_info;

  const jss = language === "arabe" ? create({ plugins: [...jssPreset().plugins, rtl()]}) : create({ plugins: [...jssPreset().plugins]});


  return (
    <Resources resources={resources}>
      <div dir={language === "arabe" ? "rtl" : "ltr"}>
      <StylesProvider jss={jss}>
      <ThemeProvider theme={theme(language === "arabe" ? "rtl" : "ltr")}>
        <CssBaseline />
          {children}
        </ThemeProvider>
      </StylesProvider>,
      </div>
    </Resources>
    );
}

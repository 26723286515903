
import React from 'react'
import { Route, Switch } from 'react-router-dom';

import Application from './Application';
import Layout from './Application/Layout';

import Welcome from './welcome';
import Session from './session';


import Resources from './Application/Resources';


export default function Index() {

    return (
        <Switch>
            <Route path={`/:resultId/start`}
              render={routeProps => (
                <Resources resultId={routeProps.match.params.resultId}>
                <Layout resultId={routeProps.match.params.resultId}>
                  <Application resultId={routeProps.match.params.resultId}/>
                </Layout>
                </Resources>
              )}
            />

            <Route path={`/session/:sessionUrl`}
              render={routeProps => (
                  <Session sessionUrl={routeProps.match.params.sessionUrl}/>
              )}
            />

            <Route path={`/:resultId/`}
              render={routeProps => (
                <Resources resultId={routeProps.match.params.resultId}>
                  <Welcome resultId={routeProps.match.params.resultId}/>
                </Resources>
              )}
            />
            
        </Switch>
      )
  }
  


import { Box, TextField } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

import React from "react";


const Title =  ({text}) => {
  const theme = useTheme();
  const { t, i18n, ready } = useTranslation();
  const h2 = useMediaQuery(theme.breakpoints.down('md'));
  return <Typography  variant={h2 ? "h2" : "h1"} color='primary' paragraph>{t(text)}</Typography>
}
const Paragraph =  ({text}) => {
  const { t, i18n, ready } = useTranslation();
  return <Typography  paragraph variant="body2">{t(text)}</Typography>
}
const Quote     = ({text, author}) => {
    const { t, i18n, ready } = useTranslation();

  return (<Box>
    <Typography  variant="body2"><Box fontStyle="italic" >
      <q>{` ${t(text)}`}</q></Box>
    </Typography>
    <Typography  variant="body2"><Box  style={{float : "right"}}>
      {author}</Box>
    </Typography>
  </Box>)
}


/*
const Select = ({options, label}) => 
<TextField
fullWidth
className={classes.button} 
label={label}
margin="dense"
onChange={(event) => {setGender(event.target.value)}}
select
value={gender}
SelectProps={{ native: true }}
variant="outlined" 
>
<option aria-label="None" value={undefined} />
{options.map((v) => (<option  key={v} value={v}>{v}</option>))}
</TextField>
*/


const Components = {
  Title,
  Paragraph,
  Quote
};

export default block => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      ...block
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>
  );
};

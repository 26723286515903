import React, { useCallback } from'react';
import{makeStyles, withStyles} from'@material-ui/core/styles';
import { LinearProgress, Typography,  Box, Radio, Grid, FormControlLabel } from '@material-ui/core';
import Image from'material-ui-image';
import Modal from '@material-ui/core/Modal';

import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

import Avatar from'@material-ui/core/Avatar';
import Button from'@material-ui/core/Button';

import{CircularProgress} from'@material-ui/core';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import TextField from '@material-ui/core/TextField';



const useStyles = makeStyles((theme) => ({
  box : {
    cursor: "pointer",    
    "&:hover": {
      backgroundColor: "#026FC2",
    }
  },
  flex : {
    display : 'flex'
  },

  typo : {
    display: "flex",
    alignItems: 'center',
    marginLeft:'10px',
    height : "100%",
    color : '#334C99',
    "&:hover": {
      color: "#fff",
    }
  },

  image : {
    width : "80%",
    height : "80%",
  },
  parent : {
    "&:hover": {
      boxShadow: "0 0 4px #ccc"
    }
  },
  avatar : {
    position : 'absolute',
    backgroundColor : '#fff',
    color : '#000',
    marginTop : 9,
    marginLeft : 9
  },
  wrapper : {
    margin : theme.spacing(1),
    position : 'relative',
    marginLeft : 'auto',
    float : 'right'
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  }

}));





function ResponseText({text, onClick}) {
  const classes = useStyles();
  const { t, i18n, ready } = useTranslation();
  return (
    <Box className={classes.box} border={1} borderColor='primary.main' height='57px'  onClick={onClick}>
     <Typography className={classes.typo} variant="body1" >{t(text)}</Typography>
    </Box>);
}



function ImageResponse({imgURL, onClick}) {
  const classes = useStyles();
  return (
    <div className={classes.parent}>
          <div     className={classes.image}>

    <Image
      //aspectRatio = {4 / 3}
      onClick={onClick}
      animationDuration = {500}
      src={imgURL}
      />
          </div>

      </div>

  );
}



function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}


const Feedback = ({onClick}) =>  {
  const [clarity, setClarity]   = useState(0);
  const [understanding, setUnderstanding] = useState(0);
  const [rewording, setRewording] = useState(null);

  return (
    <div>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography variant="h5" >Clarity</Typography>
          <Rating
            value={clarity}
            max={6} 
            onChange={(event, newValue) => {
              setClarity(newValue);
            }}
          />
        </Box>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography variant="h5" >Understanding</Typography>
          <Rating
            value={understanding}
            max={6} 
            onChange={(event, newValue) => {
              setUnderstanding(newValue);
            }}
          />
        </Box>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography variant="h5" >Rewording</Typography>
          <TextField
            multiline
            rows={2}
            style={{width: '100%'}}
            variant="outlined"
            value={rewording || ''}
            onChange={(e) => {setRewording(e.target.value)}}
          />
        </Box>
        <Button  style={{float: 'right'}} variant="contained" color="primary" disabled={!(understanding && clarity)} onClick={() => onClick({feedback : {clarity, understanding, rewording}})} >Default</Button>
    </div>
  )
}

export default function Response({title, time, chrono, imgURL, questionType, responseType, responses, id, onClick=Function.prototype, feedback=false}) {
  const classes = useStyles();


  const [timeToEnd, setTimeToEnd] = useState(time)
  const [cheeting, setCheeting] = useState(undefined);
  const { t, i18n, ready } = useTranslation();
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const onClickB = (arg) => {
    if(feedback && !response) {
      setResponse(arg)
      return handleOpen();
    }
    const data = response || {};
    onClick({...arg, ...data});
    setResponse(null);
    handleClose();
    setCheeting(undefined);
  }

  useEffect(() => {
    setTimeToEnd(time);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [id])

  const onBlur = useCallback(() => {
    setCheeting(true);
  }, [])

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  });


  useInterval(() => {
    if(!chrono)
      return;
    if(timeToEnd === 0)
      return onClickB({value : null, timeEnd : true, error : 'timeEnd', cheeting});
    setTimeToEnd(timeToEnd - 1);
  }, 1000);



  const progressValue = Math.floor(((timeToEnd) / time) * 100);

  const width = imgURL ? "1200px" : '832px';

  return (
    <div style={{
      maxWidth : width,
    //  display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center"
    }}>
    
    <div className={classes.flex}>
      <Typography style={{ marginLeft : '5px'}} variant="h4" color='primary' >{t(title)}</Typography>
      {chrono ? <div className={classes.wrapper}>
        <Avatar className={classes.avatar}>{timeToEnd}</Avatar>
        <CircularProgress className={classes.fabProgress} variant={'static'} value={progressValue} size={60} thickness={7}/>
      </div> : null}
    </div>


    <Grid container spacing={5} style={{marginTop : '20px'}}>

    {imgURL ? <Grid md={6} xs={12} item style={{margin : 'auto'}}>
        <Image
          aspectRatio = {4 / 3}
          animationDuration = {500}
          src={imgURL}
        />
      </Grid> : null}

      <Grid md={imgURL ? 6 : 12} xs={12} item>
        <Grid container spacing={2} style={{height: "100%", alignContent : "center"}}>
          {responses.map((r, i) => <Grid xs={responseType === 'text' ? 12 : 6} item key={i}>
            { responseType === 'text' ?
              <ResponseText
                text={r.text}
                onClick={() => onClickB({value : String(i), cheeting})}
                value={String(i)}
              /> : 
              <ImageResponse imgURL={r.imgURL} onClick={() => onClickB({value : String(i), cheeting})}/>
            }
          </Grid>)}
      </Grid>
    </Grid>



    </Grid>
    {open && <Modal 
        open={open}
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}

       // onClose={handleClose}
      >
        <div className={classes.modal}>
          <Feedback onClick={onClickB}/>
        </div>
        
      </Modal>
    }

    </div>
  );
}




import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  useHistory,
} from "react-router-dom";


import { useQuery, gql, useMutation } from '@apollo/client';
import { Box, CircularProgress, Grid, Hidden } from '@material-ui/core';
import Intro from '../Application/Intro'
import Lottie from '../Application/Lottie';



const QUERY = gql`
  query result($id : uuid!) {
    result : results_by_pk(id : $id) {
      id
      session {
        questionnaireId
        startDate
        endDate
        program{
          name
        }
      }
      participant {
        firstName
        lastName
      }
      allQuestions : questions_responses_aggregate{
        aggregate {
          count
        }
      }
      respondedQuestions : questions_responses_aggregate(where : {response : {_is_null : false}}){
        aggregate {
          count
        }
      }
    }
  }
`;


export default function Index(props) {
  const {resultId} = props;

  const { loading, error, data } = useQuery(QUERY, {variables : {id : resultId}});
  const { t, i18n, ready } = useTranslation();
  let history = useHistory();

  if (loading || !data || !ready)  return <Box display='flex' height='100%'><CircularProgress style={{margin : 'auto'}}/></Box>

  

  const start = () => history.push(`/${resultId}/start`); 


  if (loading || !data || !ready)  return <Box display='flex' height='100%'><CircularProgress style={{margin : 'auto'}}/></Box>


  const progression = (data.result.respondedQuestions.aggregate.count / data.result.allQuestions.aggregate.count) * 100;


  
  var main = null;
  if(progression === 100)
    main = <Intro title={t('We miss you too.')} subtitle={t('We will see you soon.')} noButton/>
  else 
    main = (progression > 0) ? <Intro title={'Welcome Back to the MASTER test.'} 
    subtitle={t("You already accomplished {{progression}}% of the test, Hit the resume button when you are ready.", {progression : parseInt(progression)})} 
    buttonTitle={t('resume')} 
    start={start}/> : 
    <Intro start={start} title={t('Welcome')} subtitle={t('WelcomeSubtitle', {programName : data.result.session.program.name})} buttonTitle={t('next')} />

  return <Grid container style={{height: "100%"}}>
  <Grid sm={3} xs={4} style={{height: "100%"}}>
    <Lottie animationIndex={18}/>
  </Grid>
   <Grid sm={4} xs={8}>
    {main}
    </Grid>
 <Hidden smDown>
   <Grid sm={5}>
   <Lottie animationIndex={19}/>
 </Grid></Hidden>
  </Grid>
}


import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';


palette.background.default = "#fff"

const theme = (direction) => createMuiTheme({
  direction: direction === 'rtl' ? 'rtl' : 'ltf',
  palette,
  typography : {...typography, fontFamily : direction === 'rtl' ? 'Tajawal' : 'Helvetica'},
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});



export default theme;